*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.relative {
  position: relative;
}

.isolate {
  isolation: isolate;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-\[17rem\] {
  margin-bottom: 17rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.contents {
  display: contents;
}

.h-\[calc\(100vh-80px\)\] {
  height: calc(100vh - 80px);
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-xl {
  max-width: 36rem;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.border-t {
  border-top-width: 1px;
}

.border-t-tickle-me-pink-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 228 234 / var(--tw-border-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-snug {
  line-height: 1.375;
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

nav h1:before {
  content: "";
  background-image: url("checkdigit-logo.a8bbde61.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 87% 86%;
  border: 1px solid #ffb2c8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.background-image {
  position: relative;
  overflow: hidden;
}

.background-image:before {
  content: "";
  z-index: -1;
  opacity: .7;
  transform-origin: center;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 70 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 13.5H61M5 41H57C59.2091 41 61 39.2091 61 37V5C61 2.79086 59.2091 1 57 1H5C2.79086 1 1 2.79086 1 5V37C1 39.2091 2.79086 41 5 41ZM44 34H52C53.1046 34 54 33.1046 54 32V28C54 26.8954 53.1046 26 52 26H44C42.8954 26 42 26.8954 42 28V32C42 33.1046 42.8954 34 44 34Z' stroke='%23FFB2C8'/%3e%3c/svg%3e ");
  background-position: center;
  background-size: 100px;
  width: 200vw;
  height: 200vw;
  position: absolute;
  bottom: 0;
  transform: translateX(0%)translateY(30%)rotate(45deg);
}

.background-image:after {
  content: "";
  z-index: -1;
  background-image: linear-gradient(#fff 60%, #0000);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 768px) {
  nav h1:before {
    bottom: 1px;
  }
}

#privacy-main.background-image:after {
  background-image: linear-gradient(#fff 98%, #0000);
}

.hover\:text-tickle-me-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgb(252 137 172 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:underline-offset-4:hover {
  text-underline-offset: 4px;
}

@media (width >= 768px) {
  .md\:order-last {
    order: 9999;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:h-\[calc\(100vh-125px\)\] {
    height: calc(100vh - 125px);
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:grow {
    flex-grow: 1;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:leading-loose {
    line-height: 2;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }
}

@media (width >= 1536px) {
  .\32 xl\:max-w-5xl {
    max-width: 64rem;
  }

  .\32 xl\:text-left {
    text-align: left;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .\32 xl\:leading-loose {
    line-height: 2;
  }

  .\32 xl\:leading-snug {
    line-height: 1.375;
  }
}
/*# sourceMappingURL=index.e83ec017.css.map */
